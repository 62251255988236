<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> Edit merchant</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
						<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">shop <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select class="form-control" v-model="merchantRequest.shop" @change="validationRule()">
										<option value="">Choose Shop</option>
										<option v-for="shop in shopList" :value="shop.id">{{shop.name}}</option>
									</select>
									<small class="text-danger">{{validationData.shop}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Name <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="merchantRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPhone" class="col-sm-2 col-form-label">phone <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="merchantRequest.phone" @blur="validationRule()"
										class="form-control" id="inputPhone">
									<small class="text-danger">{{validationData.phone}}</small>
									<small class="text-danger">{{errorAlert}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPassword" class="col-sm-2 col-form-label">Password <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="password" v-model="merchantRequest.password" @blur="validationRule()"
										class="form-control" id="inputPassword">
									<small class="text-danger">{{validationData.password}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputConfirmPassword" class="col-sm-2 col-form-label">Confirm Password <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="password" v-model="merchantRequest.conPassword" @blur="validationRule()"
										class="form-control" id="inputConfirmPassword">
									<small class="text-danger">{{validationData.conPassword}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label">Image <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="merchantRequest.image" v-bind:src="merchantRequest.image" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label"> Current Image </label>
								<div class="col-sm-10">
									<img v-if="currentImage" :src="env.mediaUrl+currentImage" class="banner-image mt-2"/>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'merchantEdit',
		metaInfo: {
            title: "Merchant Edit",
            titleTemplate: "%s ← HoHoDiDi Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				shopList: [],
				currentImage: "",
				errorAlert: "",
				filter: {
					page: "",
                    reverse: "",
                    sort: "",
                    name: "",
                    phone: "",
                    shop: "",
				},
				merchantRequest: {
					id: "",
					shop: "",
					name: "",
					phone: "",
					password: "",
					conPassword: "",
					image: ""
				},
				validationData: {
					shop: "",
					name: "",
					phone: "",
					conPassword: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				merchantUpdateAction: 'merchantUpdateAction',
				merchantFieldsAction: 'merchantFieldsAction'
			}),
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.merchantRequest.image = e.target.result
				};
			},
			readExtraImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.merchantRequest.extraImage = e.target.result
				};
			},
			async getFields(){
				let option = {
					fields: {type: "update", id: this.merchantRequest.id}
				}
				await this.merchantFieldsAction({
					...option
				}).then(res => {
					this.shopList = res.data.shop
					this.merchantRequest.shop = res.data.merchant.shopId
					this.merchantRequest.name = res.data.merchant.name
					this.merchantRequest.phone = res.data.merchant.phone
					this.currentImage = res.data.merchant.image
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.merchantRequest
				await this.merchantUpdateAction({
					...option
				}).then(res => {
					if(res.status == "fail"){
						this.errorAlert = "This phone number is already taken."
					}else{
						this.back()
					}
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'merchant-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, name: this.filter.name, phone: this.filter.phone, shop: this.filter.shop} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.merchantRequest.shop == "") {
					this.validationData.shop = "The shop field is required."
					isValidation.push(true)
				}else if (this.merchantRequest.shop == null) {
					this.validationData.shop = "The shop field is required."
					isValidation.push(true)
				} else {
					this.validationData.shop = ""
					isValidation.push(false)
				}
				if (this.merchantRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.merchantRequest.phone == "") {
					this.validationData.phone = "The phone field is required."
					isValidation.push(true)
				} else {
					this.validationData.phone = ""
					isValidation.push(false)
				}
				if (this.merchantRequest.password != "") {
					if(this.merchantRequest.conPassword == ""){
					this.validationData.conPassword = "The confirm password field is required."
					isValidation.push(true)
					}else{
						this.validationData.conPassword = ""
						isValidation.push(false)
					}
					if(this.merchantRequest.password != this.merchantRequest.conPassword){
						this.validationData.conPassword = "The password field does not match!"
						isValidation.push(true)
					}else{
						this.validationData.conPassword = ""
						isValidation.push(false)
					}
				}

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
			this.merchantRequest.id = this.$route.query.id
			this.filter.name = this.$route.query.name
			this.filter.phone = this.$route.query.phone
			this.filter.shop = this.$route.query.shop
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>